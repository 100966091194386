import React from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './toast.css'

const CloseButton = ({ closeToast }: { closeToast: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void }) => (
  <button className="Toastify__close" onClick={closeToast}>
    Close
  </button>
)

export default function Toast() {
  return <ToastContainer closeButton={CloseButton} autoClose={2000} />
}
